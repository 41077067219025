<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col md="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <ValidationProvider name="region" vid="region_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="region_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('cotton_config.region') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="units.region_id"
                                                :options="regionList"
                                                id="region_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="zone" vid="zone_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="zone_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('cotton_config.zone') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="units.zone_id"
                                                :options="zoneList"
                                                id="zone_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="district" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="units.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="upazila" vid="upazilla_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazilla_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="units.upazilla_id"
                                                :options="upazilaList"
                                                id="upazilla_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Unit Name (En)" vid="unit_name" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="unit_name"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('cotton_config.unit_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                id="unit_name"
                                                v-model="units.unit_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="Unit Name (Bn)" vid="unit_name_bn" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="project_name_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('cotton_config.unit_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                id="unit_name_bn"
                                                v-model="units.unit_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { unitStore, unitUpdate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getResionData()
            this.units = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            units: {
                region_id: '0',
                zone_id: '0',
                district_id: '0',
                upazilla_id: '0',
                unit_name: '',
                unit_name_bn: ''
            },
            zoneList: [],
            districtList: [],
            upazilaList: []
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        regionList () {
            return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
        }
    },
    watch: {
        'units.region_id': function (newVal, oldVal) {
            this.zoneList = this.getZoneList(newVal)
        },
        'units.zone_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'units.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        }
    },
    methods: {
        getResionData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(agriMarketingServiceBaseUrl, `${unitUpdate}/${this.id}`, this.units)
            } else {
                result = await RestApi.postData(agriMarketingServiceBaseUrl, unitStore, this.units)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('agriMarketing/mutateAgriMarketingProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getZoneList (regionId) {
            const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1 && item.region_id === regionId)
            return zoneList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getDistrictList (zoneId) {
            const obj = this.$store.state.agriMarketing.commonObj.zoneList.find(item => item.status === 1 && item.value === parseInt(zoneId))
            let list = []
            const districtIdArr = JSON.parse(obj.district_ids)
            if (obj.district_ids && Array.isArray(districtIdArr)) {
                list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && districtIdArr.includes(parseInt(item.value)))
            }
            return list
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        }
    }
}
</script>
